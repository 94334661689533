










.dropdown_comm {
  display: inline-block;
  min-width: 104px;
}
.el-dropdown {
  width: 100%;
  &-selfdefine {
    width: 100%;
    text-align: left;
  }
  & > button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    .svg {
      display: inline-block;
      width: 10px;
      color: inherit;
      &-morevertical {
        margin-right: -6px;
      }
    }
  }
}
.el-dropdown-menu {
  min-width: 102px;
  margin: 4px 0 !important;
  border: 1px solid #555;
  border-radius: 2px;
  box-shadow: none;
  .popper__arrow {
    display: none;
  }
  &--type {
    &-column {
      display: table;
    }
  }
  &__item {
    padding: 0 11px;
    font : {
      size: 12px;
    }
    &:focus,
    &:not(.is-disabled):hover {
      background-color: #fffbed;
      color: #555555;
    }
  }
}
.el-dropdown-column {
  display: table-cell;
  min-width: 102px;
  border-left: 1px solid #e1e1e1;
  &:first-child {
    border: none;
  }
  &__item {
  }
}
