











































































































































































































































































































































































































































































































































































































.ellips-box {
  position: relative;
  &__area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.dropdown {
  display: inline-block;
  &-info {
    display: inline-block;
    width: auto;
    margin-right: 4px;
    vertical-align: middle;
  }
  &_comm {
    vertical-align: middle;
  }
}
.more-vertical {
  font-size: 16px;
  vertical-align: middle;
}
.send-payment,
.send-alarm,
.sync-invoice {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
